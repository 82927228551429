var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"projects-table_wrap"},[_c('div',{staticClass:"projects-header_wrap"},[(_vm.isMaster)?_c('h2',{staticClass:"projects-table_header"},[_vm._v("Projets")]):_vm._e(),(_vm.isMaster)?_c('router-link',{staticClass:"add-wish_wrap",attrs:{"to":{
                name: 'project',
                params: {
                    id: 0,
                    applicationId: _vm.$route.params.applicationId
                },
            }}},[_c('button',{staticClass:"add-wish"},[_c('span',[_vm._v("Ajouter un projet")]),_c('img',{attrs:{"src":require("@/assets/images/arrow-right.svg")}})])]):_vm._e()],1),(_vm.isMaster)?_c('div',{staticClass:"form-status_wrap"},[_c('md-table',{attrs:{"value":_vm.applicationProjects},scopedSlots:_vm._u([{key:"md-table-row",fn:function({ item }){return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"Etablissement"}},[_vm._v(_vm._s(item.projectInstitutionName))]),_c('md-table-cell',{attrs:{"md-label":"Formation"}},[_vm._v(_vm._s(item.coursewishname))]),_c('md-table-cell',{attrs:{"md-label":"Domaine"}},[_vm._v(_vm._s(_vm.getName(_vm.courseWishField,item.coursewishfieldId)))]),_c('md-table-cell',{attrs:{"md-label":"Niveau"}},[_vm._v(_vm._s(_vm.getName(_vm.courseWishLevel,item.coursewishlevelId)))]),_c('md-table-cell',{attrs:{"md-label":"Type "}},[_vm._v(_vm._s(_vm.getName(_vm.courseWishProgram, item.coursewishprogramId)))]),_c('md-table-cell',{attrs:{"md-label":"Année"}},[_vm._v(_vm._s(_vm.getName(_vm.courseWishYear,item.coursewishyearId)))]),_c('md-table-cell',{attrs:{"md-label":"Statut"}},[_vm._v(_vm._s(_vm.selectedProjectStatus(item.projectStatusId)))]),_c('md-table-cell',{attrs:{"md-label":"Actions"}},[_c('router-link',{attrs:{"to":{
                            name: 'project',
                            params: {
                                id: item.id,
                                applicationId: _vm.$route.params.applicationId
                            },
                    }}},[_c('md-button',{staticClass:"btn-edit"},[_c('p',[_vm._v("Editer")])])],1),_c('md-button',{staticClass:"btn-delete",class:{ isdisabled: _vm.applicationProjects.length === 1 },attrs:{"disabled":_vm.applicationProjects.length === 1},on:{"click":function($event){return _vm.deleteProject(item.id)}}},[_c('p',[_vm._v("DELETE")])])],1)],1)}}],null,false,1667388463)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }