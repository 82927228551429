<template>
    <div class="projects-table_wrap">
        <div class="projects-header_wrap">
            <h2 v-if="isMaster" class="projects-table_header">Projets</h2>
            <router-link
                v-if="isMaster"
                :to="{
                    name: 'project',
                    params: {
                        id: 0,
                        applicationId: $route.params.applicationId
                    },
                }"
                class="add-wish_wrap"
            >
                <button class="add-wish">
                    <span>Ajouter un projet</span>
                    <img src="@/assets/images/arrow-right.svg" />
                </button>
            </router-link>
        </div>
        <div v-if="isMaster" class="form-status_wrap">
            <md-table :value="applicationProjects">
                <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item }"
                >
                    <md-table-cell md-label="Etablissement">{{ item.projectInstitutionName }}</md-table-cell>
                    <md-table-cell md-label="Formation">{{ item.coursewishname }}</md-table-cell>
                    <md-table-cell md-label="Domaine">{{ getName(courseWishField,item.coursewishfieldId) }}</md-table-cell>
                    <md-table-cell md-label="Niveau">{{ getName(courseWishLevel,item.coursewishlevelId) }}</md-table-cell>
                    <md-table-cell md-label="Type ">{{ getName(courseWishProgram, item.coursewishprogramId) }}</md-table-cell>
                    <md-table-cell md-label="Année">{{ getName(courseWishYear,item.coursewishyearId) }}</md-table-cell>
                    <md-table-cell md-label="Statut">{{ selectedProjectStatus(item.projectStatusId) }}</md-table-cell>
                    <md-table-cell md-label="Actions">
                        <router-link
                            :to="{
                                name: 'project',
                                params: {
                                    id: item.id,
                                    applicationId: $route.params.applicationId
                                },
                        }"
                        >
                            <md-button class="btn-edit">
                                <p>Editer</p>
                            </md-button>
                        </router-link>
                        <md-button :disabled="applicationProjects.length === 1" :class="{ isdisabled: applicationProjects.length === 1 }"  class="btn-delete" @click="deleteProject(item.id)">
                            <p>DELETE</p>
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'Projects',
    computed: {
        ...mapGetters(['simpleLists']),
        ...mapGetters('applications',['applicationProjects', 'currentScholarshipTypeId']),
        institutionNames() {
            return this.simpleLists?.institutionname
        },
        courseWishLevel() {
            return this.simpleLists?.coursewishlevel
        },
        courseWishYear() {
            return this.simpleLists?.coursewishyear
        },
        courseWishField() {
            return this.simpleLists?.coursewishfield
        },
        courseWishProgram() {
            return this.simpleLists?.coursewishprogram
        },
        projectStatuses() {
          return this.simpleLists?.projectStatus
        },
        isMaster() {
            return parseInt(this.currentScholarshipTypeId) === 4
        },
    },
    async mounted() {
        await this.getAllScholarships()
        .then(() => this.getApplicationProjects(this.$route.params.applicationId))
        if(this.applicationProjects.length === 1 && !this.isMaster) {
            this.$router.push(
                {   name: 'project',
                    params: {
                        id: this.applicationProjects[0].id,
                        applicationId: this.$route.params.applicationId
                    },
                })
        }
    },
    methods: {
        ...mapActions('applications',['getApplicationProjects', 'getProject', 'getAllScholarships', 'deleteProject']),
        getName(list, id) {
            let name = ' ';
            if(!id) return ' - '
            list.forEach(el => {
                if(el.id === id) {
                    name = el.name
                }
            });
            return name;
        },
        selectedProjectStatus (status) {
          let statusName
          this.projectStatuses.map(item => {
            if (item.id === status) {
              statusName = item.name
            }
          })
          return statusName
        }
    }
}
</script>

<style lang="scss">
.isdisabled {
    background: #c1bbbb;
}
.projects-table_wrap {
    width: 100%;
    .md-table-head:last-child {
        .md-table-head-container {
            display: flex !important;
            justify-content: center !important;
        }
    }
    .form-status_wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        form {
            width: 100%;
        }
    }
    .md-table {
        width: 100%;
        box-shadow: unset !important;
    }
    .md-table + .md-table {
        margin-top: 16px
    }
    .md-ripple, .md-table-cell-container {
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        color: #000000;
        text-align: left;
    }
    .md-table-fixed-header-container > table > thead > tr {
        border-bottom: 1px solid #000000;;
    }
    .md-table-head-label {
        font-weight: bold;
    }

    .status {
      .md-table-cell-container {
        padding: 6px 32px 6px 0;
      }
    }
}
</style>

<style lang="scss" scoped>
.md-theme-default a:not(.md-button) {
    color: #FFFFFF;
    &:hover {
        color: #FFFFFF;
    }
}
.projects-table_wrap {

    .projects-header_wrap{
        display: flex;
        justify-content: space-between;
        margin: 0 0 35px 30px;
    }

    .add-wish {
        width: 220px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        background-color: #0045FF;
        color: #FFFFFF;
        border-radius: 6px;
        outline: none;
        border: none;
        cursor: pointer;

        span {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            margin-right: 10px;
        }

        &_wrap {
            text-decoration: none;
        }
    }

    .document-status {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
        div {
            cursor: pointer;
        }
    }
    .projects-table_header {
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
        text-align: left;
    }

    .form-field {
        display: flex;
        align-items: center;
        //margin-bottom: 25px;

        p {
            width: 190px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
        }
        .md-field {
            width: 45%;
            max-width: 410px;
            background: #FFFFFF;
            border: 1px solid #CDD0D9;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 0 0 0 16px;
            padding: 0 0 0 24px;

            .md-input {
                height: 48px !important;
            }
            .md-file {
                flex-direction: row-reverse;
            }
        }
        .uploaded {
            background: #DCDCDC;
        }
        .md-field:after, .md-field:before {
            position: unset !important;
        }
        .select-field {
            background: #FFFFFF;
            border: 1px solid #CDD0D9;
            outline: none;
            box-sizing: border-box;
            border-radius: 4px;
            height: 48px;
            padding: 0 0 0 25px;
            margin-left: 6px;

            option {
                height: 48px;
            }
        }
    }
    .form-field_textarea {
        align-items: flex-start;
        .md-field {
            padding: 0;
            .md-textarea {
                min-height: 150px;
            }
        }
    }
    .applications-error {
        margin-bottom: 0;
    }
    .cancel {
        width: 130px;
        height: 40px;
        margin-left: 25px;
        background: #FFFFFF;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
        }
    }
    .next {
        width: 130px;
        height: 40px;
        margin-left: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        border-radius: 6px;
        background-color: #0045FF;
        color: #FFFFFF;

        img {
            margin-left: 13px;
        }
    }
    .radio-wrap {
        margin-left: 16px;
    }
    .btn-edit {
        border: 1px solid #CDD0D9;
        border-radius: 6px;

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #000000;
        }
    }
    .btn-delete {
        border: 1px solid #CDD0D9;
        border-radius: 6px;

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #000000;
        }
    }
}
</style>